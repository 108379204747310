const Frame = ({
                   id,
                   title,
                   className,
                   isRutube
               }: {
    id: string;
    title: string;
    className?: string;
    isRutube?: boolean
}) => {
    return (
        <iframe
            className={`video-frame ${className}`}
            src={isRutube ? `https://rutube.ru/play/embed/${id}` : `https://www.youtube.com/embed/${id}`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></iframe>
    );
};

export default Frame;