// noinspection SillyAssignmentJS

import {MouseEventHandler, useState} from "react";
import "./miniVideoPlayer.css";

const MiniVideoPlayer = ({id, isRutube, title}: { id: string, isRutube?: boolean, title: string }) => {
    const [isOpened, setIsOpened] = useState(false);

    const frameId = "introduce_video_frame_" + id;
    const handleHideToggleFrame: MouseEventHandler<any> = (e) => {
        const iframe: HTMLIFrameElement = document.getElementById(frameId) as HTMLIFrameElement;
        if (iframe) {
            // eslint-disable-next-line
            iframe.src = iframe.src; //Reload all iframes again to stop videos
            // iframe.style.display = "block"; //shows the frame
            // iframe.style.display = "none";
        }
        setIsOpened(prev => !prev);
        e.preventDefault();
        e.stopPropagation();
    }
    return (
        <div className={`player-container ${isOpened ? "opened" : ""}`} onClick={handleHideToggleFrame}>
            <iframe
                id={frameId}
                className={`video-player mini__video-player`}
                src={isRutube ? `https://rutube.ru/play/embed/${id}` : `https://www.youtube.com/embed/${id}`}
                title={title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
            {isOpened ? (
                <button type="button" className="player-btn" onClick={handleHideToggleFrame}>
                    <div className="player-minus"/>
                </button>
            ) : (
                <button type="button" className="player-btn" onClick={handleHideToggleFrame}>
                    <div className="player-cross"/>
                    <div className="player-cross" style={{rotate: "-90deg"}}/>
                </button>
            )}
        </div>
    );
    /*
    const {t} = useTranslation();
    const [player, setPlayer] = useState<null | any>(null);

    const onPlayerReady = useCallback((event: any) => {
        setPlayer(event.target);
        const p = event.target;
        // p.playVideo();
        p.mute();
    }, []);

    const handleOpen: MouseEventHandler<HTMLDivElement> = (e) => {
        if (isOpened) return;

        setIsOpened(true);
        if (player) {
            player.unMute();
            player.seekTo(0, true);
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
        setIsOpened(false);
        if (player) player.mute();
        e.preventDefault();
        e.stopPropagation();
    };

    const hide: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (player) player.stopVideo();
        setPlayer(null);
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div
            className={`player-container ${player === null ? "hidden" : ""} ${isOpened ? "opened" : ""}`}
            onClick={handleOpen}
        >
            <VideoPlayer id={id} onPlayerReady={onPlayerReady} className="mini__video-player"/>
            {isOpened ? (
                <>
                    <button type="button" className="player-btn" onClick={handleClose}>
                        <div className="player-minus"/>
                    </button>
                    <button type="button" className="player-btn-more">
                        <h2 className="text-header2" style={{margin: 0, opacity: 1}}>
                            <a
                                href={`https://youtu.be/${id}`}
                                style={{
                                    textDecoration: "none",
                                    fontSize: "1rem",
                                    color: "#fff",
                                }}
                            >
                                {t("home.video.more.btn")}
                            </a>
                        </h2>
                    </button>
                </>
            ) : (
                <button type="button" className="player-btn" onClick={hide}>
                    <div className="player-cross" style={{rotate: "45deg"}}/>
                    <div className="player-cross" style={{rotate: "-45deg"}}/>
                </button>
            )}
        </div>
    );
    */
};

export default MiniVideoPlayer;
